
import { Component, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import Emitter from '@/utils/emitter'
import { formatDate } from '@/utils/date'
import {
	getUserRole,
	isMobile,
	isPrinterConnected,
	printWithLocalPrinter,
	sleep
} from '@/utils/utils'

import * as _ from 'lodash'
import { Canvas, createCanvas, loadImage } from 'canvas'
import JsBarcode from 'jsbarcode'
import printJS from 'print-js'

@Component({
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		'search-form': ElForm
	}
	private isLoading = false
	private isEmpty = false
	private selectedRows = []
	private packageAttrStatusList = [
		{
			value: 'all',
			label: '全部'
		},
		{
			value: 0,
			label: '已取消'
		},
		{
			value: 1,
			label: '正常包裹'
		},
		{
			value: 2,
			label: '已创建'
		},
		{
			value: 3,
			label: '异常待处理'
		},
		{
			value: 4,
			label: '待取消包裹'
		}
	]
	private userRoles: any = {}
	private searchForm = {
		userRole: '',
		userCode: '',
		waybill: '',
		packageAttrStatus: 'all',
		dateTime: [] as any,
		timeType: 'jobline_time'
	}
	private validateForm = {
		userCode: [{ required: false, message: '请输入用户编码', trigger: 'blur' }]
	}
	private user = {
		role: -1
	}
	private pageConfig = {
		total: 0,
		size: 20,
		current_page: 1
	}
	private pickerOptions = {
		shortcuts: [
			{
				text: '最近一天',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近一周',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近半个月',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近一个月',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
					picker.$emit('pick', [start, end])
				}
			},
			{
				text: '最近三个月',
				onClick(picker: any) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
					picker.$emit('pick', [start, end])
				}
			}
		]
	}
	get isMobile() {
		return isMobile()
	}
	@Watch('pageConfig.current_page')
	onPagination() {
		this.searchPackages()
	}

	@Watch('searchForm.userCode')
	onInput(value: string) {
		this.userData.user_code = ''
	}
	onRouteChange() {
		const {
			user_code = '',
			size,
			current_page,
			waybill = '',
			package_attr_status = '',
			jobline_time_start = '',
			jobline_time_end = ''
		}: any = this.$route.query
		if (jobline_time_start && jobline_time_end) {
			this.searchForm.dateTime = [
				new Date(jobline_time_start * 1000),
				new Date(jobline_time_end * 1000)
			]
		}
		this.searchForm.userCode = user_code
		this.searchForm.waybill = waybill
		this.searchForm.packageAttrStatus = package_attr_status || 'all'
		this.pageConfig.size = Number(size || 20)
		this.pageConfig.current_page = Number(current_page || 1)
		if (user_code || jobline_time_start) {
			this.searchPackages()
		}
	}
	private packages = []

	private userData = {
		info: {
			avatarUrl: ''
		},
		user_code: ''
	}
	mounted() {
		this.onRouteChange()
	}
	created() {
		this.getUserRole()
	}
	resetSearch() {
		this.searchForm = {
			userCode: '',
			userRole: '',
			packageAttrStatus: '',
			waybill: '',
			dateTime: [],
			timeType: 'jobline_time'
		}
	}
	handleSelectionChange(val: any) {
		this.selectedRows = val
	}
	handelSizeChange(val: number) {
		this.pageConfig.size = val
		this.searchPackages(true)
	}
	printPackages() {
		const { selectedRows } = this
		const titles = [] as string[]
		const ids = selectedRows.map((item: any) => {
			titles.push(`${item.country}${this.getLabelTitleSuffix(item.user.role)}`)
			if (item.package_attr?.package_attr_num)
				return item.package_attr?.package_attr_num
			return `${item.package_num}-1`
		})
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		window.open(
			`${baseUrl}v1/jobline/generate/package-label?code=${ids.join(
				','
			)}&title=${titles.join(',')}`
		)
	}

	async getPackageNote(package_num: string) {
		const { data } = await this.$axios
			.get(`/v1/jobline/package-note/${package_num}`)
			.catch(() => {
				return { data: null }
			})
		if (!data) return null
		const userRemark = data
			.filter((v: any) => v.user_type == 'client')
			.sort((a: any, b: any) => a.created - b.created)
		return _.first(userRemark)
	}

	async printLabel(package_num: string, title: string) {
		const { selectedRows } = this
		const isConnected = await isPrinterConnected()
		if (!isConnected) {
			this.$alert('打印机客户端未连接', '提示')
			return
		}
		const shipmentChunk = _.chunk(selectedRows, 10)
		for (const items of shipmentChunk) {
			await Promise.all(
				items.map(async (item: any) => {
					const { package_num, country } = item
					const package_attr_num =
						item.package_attr?.package_attr_num || `${item.package_num}-1`
					const title = `${country}${this.getLabelTitleSuffix(item.user.role)}`
					const remark = (await this.getPackageNote(package_num)) as any
					const url = `${
						process.env.VUE_APP_API_BASE_URL
					}v1/jobline/generate/package-bar-code/${package_attr_num}?title=${title}&remark=${
						remark ? remark.message : ''
					}`
					await printWithLocalPrinter([
						{
							package_num: package_attr_num,
							title: title,
							url: url
						}
					])
				})
			)
		}
	}
	getLabelTitleSuffix(role: number) {
		return this.userRoles[role]?.label_title_suffix || ''
	}

	async getUserRole() {
		this.userRoles = await getUserRole()
	}

	async printLabels() {
		const { selectedRows } = this
		if (!selectedRows.length) {
			return this.$message.error('请选择需要打印的面单')
		}
		if (selectedRows.length > 20) {
			return this.$message.error('单次打印不能超过20单')
		}
		const loading = this.$loading({
			text: '加载中..'
		})
		const shipmentChunk = _.chunk(selectedRows, 10)
		let list: any[] = []
		for (const items of shipmentChunk) {
			const res = await Promise.all(
				items.map(async (item: any) => {
					const { package_num, country } = item
					const package_attr_num =
						item.package_attr?.package_attr_num || `${item.package_num}-1`
					const title = `${country}${this.getLabelTitleSuffix(item.user.role)}`
					const remark = (await this.getPackageNote(package_num)) as any
					return await this.generateLabel(package_attr_num, title, remark)
				})
			)
			list = list.concat(res)
		}
		this.$nextTick(() => loading.close())
		if (list.length) {
			printJS({
				printable: list,
				type: 'image',
				base64: true,
				style:
					'@media print { @page {size: auto; margin: 0; } body{margin:0 5px}}'
			})
		}
	}

	async generateLabel(code: string, title: string, remark?: any) {
		const canvas = createCanvas(800, 1200)
		const ctx = canvas.getContext('2d')
		ctx.fillStyle = '#fff'
		ctx.fillRect(0, 0, 800, 1200)

		ctx.fillStyle = '#000'
		ctx.font = 'normal bold 60px Arial'
		ctx.textAlign = 'center'
		ctx.fillText(title, 400, 80)

		ctx.moveTo(20, 120)
		ctx.lineTo(780, 120)
		ctx.closePath()
		ctx.lineWidth = 4
		ctx.moveTo(20, 440)
		ctx.lineTo(780, 440)
		ctx.closePath()
		ctx.lineWidth = 4
		const barcode = await this.createBarCode(code)
		const barcodeImg = await loadImage(barcode)
		ctx.drawImage(barcodeImg, 40, 140, 720, 220)
		ctx.font = 'normal bold 50px Arial'
		ctx.textAlign = 'center'
		ctx.fillText(code, 400, 420)

		ctx.textAlign = 'left'
		ctx.font = 'normal bold 30px Arial'
		const text = remark ? remark.message : ''
		let line = ''
		let y = 600
		for (let i = 0; i < text.length; i++) {
			const textLine = line + text[i] + ' '
			const metrics = ctx.measureText(textLine)
			const textWidth = metrics.width
			if (textWidth > canvas.width - 60 && i > 0) {
				ctx.fillText(line, 40, y)
				line = text[i] + ' '
				y += 40
				continue
			}
			line = textLine
		}
		if (line.length) {
			ctx.textAlign = 'center'
			ctx.fillText(line, 400, y)
		}
		ctx.stroke()
		ctx.fill()
		return canvas.toDataURL('image/png')
	}
	async createBarCode(code = '') {
		const canvas = createCanvas(3, 200)
		JsBarcode(canvas, code, {
			height: 200,
			width: 3,
			textAlign: 'center',
			textMargin: 4,
			fontSize: 34,
			margin: 1,
			displayValue: false,
			fontOptions: 'bold'
		})
		return canvas.toDataURL('image/png')
	}
	async searchPackages(reset?: boolean) {
		const {
			userCode,
			waybill,
			packageAttrStatus,
			dateTime,
			userRole,
			timeType
		} = this.searchForm
		const isValidate = await this.$refs['search-form']
			.validate()
			.catch(() => false)
		if (!isValidate) return
		if (reset) {
			this.pageConfig.current_page = 1
		}
		if (this.isLoading) {
			return
		}

		if (!dateTime && !userCode) {
			this.$message.error('请输入用户编码或者选择下单/作业时间!')
			return
		}
		let startTime = 0
		let endTime = 0
		if (dateTime) {
			const [start, end] = dateTime
			startTime = Math.round(new Date(start).getTime() / 1000)
			endTime = Math.round(new Date(end).getTime() / 1000)
		}
		const { size, current_page } = this.pageConfig
		this.isLoading = true
		const params = {
			page_size: size,
			waybill: waybill,
			package_attr_status: packageAttrStatus,
			page_index: current_page,
			user_role: userRole,
			time_start: startTime,
			time_end: endTime,
			time_type: timeType
		}
		const { data } = await this.$axios
			.get(`/v1/jobline/find/${userCode}/packages`, {
				params: params
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))

		this.$router
			.push({
				query: {
					user_code: userCode,
					size: String(size),
					waybill: waybill,
					current_page: String(current_page),
					package_attr_status: String(packageAttrStatus),
					jobline_time_start: startTime ? String(startTime) : '',
					jobline_time_end: endTime ? String(endTime) : ''
				}
			})
			.catch(err => err)
		if (!data) {
			return
		}
		this.packages = data.list
		this.pageConfig.total = data.page.total
	}
}
