import { render, staticRenderFns } from "./packages.vue?vue&type=template&id=1de22687&scoped=true"
import script from "./packages.vue?vue&type=script&lang=ts"
export * from "./packages.vue?vue&type=script&lang=ts"
import style0 from "./packages.vue?vue&type=style&index=0&id=1de22687&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1de22687",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend_page_elephant/frontend_page_elephant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1de22687')) {
      api.createRecord('1de22687', component.options)
    } else {
      api.reload('1de22687', component.options)
    }
    module.hot.accept("./packages.vue?vue&type=template&id=1de22687&scoped=true", function () {
      api.rerender('1de22687', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/packages.vue"
export default component.exports